header {
    display: flex;
    flex-direction: column;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 9999;
    width: 100vw;
    padding: .125rem .5rem;
}

#logo-container {
    justify-content: center;
}

#logo {
    height: .75rem;
    margin-top: .25rem;
}

nav {
    flex-grow: 1;
}

#navigation {
    display: flex;
    justify-content: space-between;
    list-style: none;
}

@media screen and (min-width: 1024px) {
    header {
        flex-direction: row;
        gap: 5vw;
    }

    #logo {
        height: 1rem;
    }
}