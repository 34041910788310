#graphic-design {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#graphic-design img {
    width: 95vw;
}

@media screen and (min-width: 1024px) {
    #graphic-design {
        padding-left: .5rem;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: .5rem 0;
        height: 85vh;
    }


    #graphic-design img {
        height: 40vh;
        width: auto;
    }

    #super-ego {
        height: 95%;
    }
    #super-ego img {
        height: 100%;
    }
}