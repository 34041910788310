#fine-art {
    border-radius: 2vw 0 0 .5rem;
    padding-left: 2vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    white-space: nowrap;
    flex-grow: 1;
    outline: 1px dotted blue;
}


.artwork {
    display: flex;
    flex-direction: column;
}

.artwork img {
    width: 95vw;
    border-radius: 0 0 2vw 2vw;
}

.art-details {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding-left: 1vw;
}

.medium {
    font-size: .85rem;
}

.text-right {
    text-align: end;
    padding-right: 1rem;
}

@media screen and (min-width: 1024px) {

    /* Style the custom scrollbar */
    #fine-art::-webkit-scrollbar {
        margin-top: 5vw;
        width: .25rem;
        /* Width of the scrollbar */
        background-color: transparent;
        /* Background color of the scrollbar */
    }

    #fine-art::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        /* Border radius of the scrollbar thumb */
        background-color: rgba(0, 0, 0, 0.2);
        /* Background color of the scrollbar thumb */
    }

    #fine-art::-webkit-scrollbar-thumb:active,
    #fine-art::-webkit-scrollbar-thumb:hover {
        background-color: var(--tertiary);
    }

    .artwork img {
        --size: 90vh;
        width: var(--size);
    }

    #fine-art {
        flex-direction: row;
        margin-top: 2vh;
        margin-bottom: 2vh;
        gap: 2.5rem;
    }
}