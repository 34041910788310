#artist {
    display: grid;
    grid-template-columns: auto 1fr;
    border-radius: 5rem 0 0 5rem;
    align-items: center;
    padding: .3rem 0 .3rem .5rem;
    color: var(--tertiary);
}

#profile-pic {
    --size: 35vw;
    border-radius: 50%;
    height: var(--size);
    width: var(--size);
    background: url('../../assets/images/profile.jpg');
    background-size: cover;
    box-shadow: inset .25vw .25vw 4rem var(--tertiary), .2vw .2vw .5rem var(--tertiary);
}

#contact-links {
    display: flex;

}

p,
.icon {
    padding-left: 1rem;
}

@media screen and (min-width: 768px) {
    #artist {
        border-radius: 10rem 0 0 10rem;
    }

    #profile-pic {
        --size: 20vw;
        height: var(--size);
        width: var(--size);
        margin-right: 2vw;
    }
}

@media screen and (min-width: 1024px) {
    #artist {
        align-items: flex-start;
    }

    #contact {
        margin-top: 3vh;
    }
    
    h3 {
        width: fit-content;
        border-bottom: 1px solid var(--secondary);
        padding-right: .25rem;
    }
    .icon {
        font-size: 1.5rem;
    }

    #profile-pic {
        --size: 12.5vw;
        height: var(--size);
        width: var(--size);
        margin-right: 2vw;
        box-shadow: inset .25vw .25vw 2rem var(--tertiary);
    }

    .chapter {
        height: 45vh;
        width: auto;
    }
}