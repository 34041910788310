.volume {
    display: grid;
    grid-template-columns: 20vw auto;
    overflow: auto;
    gap: 1rem;
}

.title {
    text-align: end;
}
.img-wrapper {
    display: flex;
}

.illustration-img {
    width: 100%;
    height: 30vh;
    object-fit: cover;
}

@media screen and (min-width: 768px) {
    .md-hide {
        display: none;
    }
}

@media screen and (min-width: 1024px) {
    .title {
        padding-left: 1rem;
    }
    
    .volume {
        grid-template-columns: 10rem auto;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        white-space: nowrap;
    }

    .illustration-img {
        height: 50vh;
    }

    /* Style the custom scrollbar */
    .volume::-webkit-scrollbar {
        margin-top: 5vw;
        width: .25rem;
        /* Width of the scrollbar */
        background-color: transparent;
        /* Background color of the scrollbar */
    }

    .volume::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        /* Border radius of the scrollbar thumb */
        background-color: rgba(0, 0, 0, 0.2);
        /* Background color of the scrollbar thumb */
    }

    .volume::-webkit-scrollbar-thumb:active,
    .volume::-webkit-scrollbar-thumb:hover {
        background-color: var(--tertiary);
    }
}