#homepage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .5rem;
    padding: .25rem; 
    align-items: center;
}

.portfolio {
    width: 100%;
    border-radius: .25rem;
    overflow: hidden;
    position: relative;
}

.portfolio p {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    padding: 1rem;
    z-index: 1;
    padding: 0 1rem;
    background-color: var(--tertiary);
    color: var(--primary);
    width: 100%;
}

.random-img {
    border-radius: inherit;
    width: 100%;
    height: 41.25vh;
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 0;
}

@media screen and (min-width: 768px) {
    .portfolio p {
        font-size: 1.25rem;
    }
    .random-img {
        height: 42.5vh;
    }
}

@media screen and (min-width: 1024px) {
    #homepage {
        display: flex;
    }

    .random-img {
        height: 85vh;
    }
}