#graphic-novel {
    display: flex;
    flex-direction: column;
    padding: .3rem 0 .3rem .5rem;
}

.chapters {
    display: flex;
    overflow-x: scroll;
    gap: .25rem;
}

.chapter {
    width: 80vw;
}

@media screen and (min-width: 768px) {
    .chapter {
        width: 40vw;
    }
}

@media screen and (min-width: 768px) {
    .chapter {
        width: 20vw;
    }
}

@media screen and (min-width: 1024px) {

    /* Style the custom scrollbar */
    .chapters::-webkit-scrollbar {
        margin-top: 5vw;
        width: .25rem;
        background-color: transparent;
    }

    .chapters::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background-color: rgba(0, 0, 0, 0.2);
    }

    .chapters::-webkit-scrollbar-thumb:active,
    .chapters::-webkit-scrollbar-thumb:hover {
        background-color: var(--tertiary);
    }

}