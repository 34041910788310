:root {
  --primary: rgb(230, 230, 230);
  --secondary: rgba(155, 151, 151, 0.85);
  --tertiary: rgba(38, 38, 41, 0.9);
  --quaternary: rgb(142, 40, 40);
  --box-shadow: 0 1vw 2vw;
  --box-shadow-sm: 0 .5vw 1rem;
  --focus: rgba(70, 33, 33, 0.75);
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

/* scrollbar */
body::-webkit-scrollbar {
  display: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: small-caps;
  color: var(--tertiary);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  flex-grow: 1;
}

/* box */
.dark {
  background-color: var(--tertiary);
  color: var(--primary);
}

.section {
  color: var(--secondary);
  box-shadow: .25vw .25vw 1vw var(--secondary);
  margin: 2vh 0 1.5vw 1.5vw;

}

/* list styles */
ul {
  list-style: none;
}

/* typography */
main * {
  color: var(--tertiary);
}

.link, .icon * {
  cursor: pointer;
  transition: color 1s;  
}

.link:hover, .icon *:hover {
  color: var(--quaternary);
}

.selected {
  color: var(--quaternary);
}

.logo-selected {
  background: linear-gradient(to left, transparent, var(--quaternary));
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
}